
import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter, TestimonialFilter } from 'client-website-ts-library/filters';
import {
  ListingStatus,
  ListingCategory,
  Testimonial,
} from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';

import TestimonialCycler from '@/components/TestimonialCycler.vue';
import Form from '@/components/Forms/Form.vue';

import { ItemCyclerSlide, ItemCyclerImageSlide } from '../app_code/ItemCycler';

import Listings from '../components/Listings.vue';
import SideBySide from '../components/Layout/SideBySide.vue';
import ItemCycler from '../components/Cycler/ItemCycler.vue';

@Component({
  components: {
    Listings,
    SideBySide,
    ItemCycler,
    TestimonialCycler,
    Form,
  },
})
export default class Home extends Mixins(View) {
  private listingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
    ],
    PageSize: 6,
  });

  private testimonials: Testimonial[] = [];

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/1', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/2', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/3', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
  ];

  mounted() {
    API.Testimonials.Search(new TestimonialFilter()).then((testimonials) => {
      this.testimonials = testimonials;
    });
  }
}
